/* add test style sheets */

// import common-style
//@use "fi-01-common-style";

// add app specific stles and components
//@use "@material/theme";
//@use "material-components-web"; // this is without @

@use "@material/typography/mdc-typography";
@use "@material/elevation/mdc-elevation";
@use "@material/button";
@use "@material/icon-button";
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/fab";
@use "@material/list";
@use "@material/card";
@use "@material/layout-grid/mdc-layout-grid";
@use "@material/tab-bar/mdc-tab-bar";
@use "@material/tab-scroller/mdc-tab-scroller";
@use "@material/tab-indicator/mdc-tab-indicator";
@use "@material/tab/mdc-tab";
//@use "@material/tab/mixins";
//@use "@material/tab-indicator/mixins";

// related to text-fields
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
//@use "@material/textfield/mixins";
//@use "@material/button/mixins";
//@use "@material/icon-button/mixins";
//@use "@material/textfield/character-counter";

// related to check-box
@use "@material/checkbox";
@use "@material/form-field";
//@use "@material/checkbox/mixins";
@use "@material/switch";

@include button.core-styles;
@include icon-button.core-styles;
@include fab.core-styles;
@include list.core-styles;
@include card.core-styles;
@include textfield.core-styles;
//@include character-counter.character-counter-core-styles;

@include checkbox.core-styles;
@include form-field.core-styles;
@include switch.core-styles;

$pageMax: 1200px;
$contentMax: 900px;
$fi-flip-card-width: 400px;
$fi-flip-card-hieght: 400px;
$fi-office-card-width: 500px;
$fi-office-card-hieght: 500px;
$fi-card-width: 400px;
$fi-card-hieght: 400px;
$fi-logo-onpage-width: 180px;
$fi-logo-onpage-height: 180px;


@media (max-width: 550px) {
  $fi-flip-card-width: 90%;
  $fi-flip-card-hieght: $fi-flip-card-width;
  $fi-office-card-width: 90%;
  $fi-office-card-hieght: 500px;
  $fi-card-width: 90%;
  $fi-card-hieght: $fi-card-width;

  $fi-logo-onpage-width: 120px;
  $fi-logo-onpage-height: 120px;
}

@media (min-width: 550px) {
  $fi-flip-card-width: 470px;
  $fi-flip-card-hieght: 410px;
  $fi-office-card-width: 470px;
  $fi-office-card-hieght: 410px;
  $fi-card-width: 470px;
  $fi-card-hieght: 410px;

  $fi-logo-onpage-width: 160px;
  $fi-logo-onpage-height: 160px;
}





* {
  //box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  //width: 100vw;
  max-width: 100%;
  margin: 1px;
  //background: #ffffff; //linear-gradient(180deg, rgb(252, 255, 255) 33%, rgb(229, 229, 252) 33%, rgb(238, 244, 250) 33%);
  background: #fafafa; // linear-gradient(45deg, rgba(2, 0, 36, 0.01) 0%, rgba(9,9,121,0.5) 35%, rgba(0,212,255,0.1) 100%);
}

.page-top-banner {
  //background: #ffffff ; //linear-gradient(45deg, rgb(0, 0, 0) 1%, rgba(11, 11, 14, 0.247) 35%, rgb(255, 255, 255) 100%);
  //background: linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
  color: #000000;
  text-align: center;
  //border: 1px solid red;
}

.page-cookie-section {
  border: 2px solid lightgrey;
  position: sticky;
  top: 0;
  //right:0;
  //left:0;
  //width: 100%;
  max-width: 100%;
  background: lightgrey; // rgb(241, 239, 239);
  color: black; // lightpink;
  //height: 20px;
  margin: 0px;
  padding: 0px;
  z-index: 2;
  display: block;

  .fi-cookie-area,
  .fi-cookie-message-row {
    margin: 0px;
    padding: 0px;
  }

  .fi-cookie-message {
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
  }

  .fi-cookie-message-text {
    text-align: left;
    margin: 0px;
    padding: .5em;
  }

  .fi-cookie-message-link {

    a:link,
    a:visited {
      background-color: green;
      color: white;
      text-decoration: underline;
      //cursor: ;
    }

    a:active,
    a:hover {
      color: pink;
    }

    a:visited {
      color: whitesmoke;
    }
  }

  .fi-cookie-message-buttons {
    margin: 0px;
    padding: 0em;
    display: flex;
    flex-direction: column;
  }

  .fi-cookie-message-buttons-decline,
  .fi-cookie-message-buttons-accept {
    margin: 0.5px;
    padding: 0.2em;
  }

  .fi-cookie-message-buttons-accept {
    button {
      background: green;
      color: white;
    }

    a:link,
    a:visited {
      background: green;
      color: white;
      text-decoration: none;
      //cursor: ;
    }

    a:active,
    a:hover {
      color: pink;
    }

    a:visited {
      color: whitesmoke;
    }
  }

  .fi-cookie-message-buttons-decline {

    a:link,
    a:visited {
      background: rgb(74, 2, 2);
      color: white;
      text-decoration: none;
      //cursor: ;
    }

    a:active,
    a:hover {
      background: red;
    }

    a:visited {
      color: whitesmoke;
    }
  }
}

.root-container {
  //position: fixed;
}

.page-main-section {
  //background: linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0) ), url('fi-footer-area-nav-sec3/fi-hero-img01.png');
  //background: linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0) );
  //background: linear-gradient(45deg, #4fa49a, #4361c2);
  background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
  //background-repeat: no-repeat;
  background-size: cover;
  //opacity: 0.3;
  //color: #ffffff;
  color: #000000;
  text-align: center;

}

.page-services-section {
  //background: linear-gradient(45deg, rgb(251, 251, 252) 0%, rgb(234, 245, 252) 35%, rgb(251, 251, 252) 0%);
  background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
  color: #000000;
  text-align: center;
}

.page-aboutus-section {
  //background: linear-gradient(45deg, rgb(13, 13, 14) 0%, rgb(43, 43, 202) 35%, rgb(39, 5, 5) 100%);
  //background: linear-gradient(45deg, rgb(250, 251, 252) 0%, rgb(156, 215, 243) 35%, rgb(247, 245, 245) 100%);
  background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
  color: #000000;
  text-align: center;
}

.page-contactus-section {
  background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
  color: #000000;
  text-align: center;
}

.page-offices-section {
  background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
  color: #000000;
  text-align: center;
}


.mdc-top-app-bar {
  //background: linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
  color: #000000;
  min-height: 60px;

  //background-color: #ffffff;
  //border: 1px solid red;
  .fi-app-bar-menu-icon {
    padding: 1px;
    width: 60px;
    height: 60px;
    color: #000000;

    img {
      width: 56px;
      height: 56px;
    }
  }

  .fi-app-bar-title {
    text-transform: uppercase;
    //font-size: 16px;
    //font-size: 1.5vw;
    letter-spacing: 3px;
    color: #000000;
    width: 180px;
    width: 100%;

  }

  .fi-app-bar-btn {

    //display: block;
    min-width: 150px; // 100%;
    // width: 100%;
    /*margin-right: 5px;
        margin-left: 5px;
        margin-top: 0px;
        margin-bottom: 0px;*/
    //padding: 2px;
  }

  .fi-app-bar-btn-lbl {
    //width: 100%;
    text-transform: capitalize;
    //font-size: 16px;
    //font-size: 1.5vw;
    letter-spacing: 3px;
    color: #000000;
    text-align: center;
  }

  .fi-top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .fi-app-bar-menu-icon {
      display: none;
    }

    .fi-app-bar-menu-items {
      display: flex;
    }
  }

  @media screen and (max-width: 1350px) {
    .fi-top-nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
      color: #000000;
      // border: solid 1px gray;
      // transition: color 10s ease;

      .fi-app-bar-menu-icon {
        display: flex;
        justify-content: center;
        align-self: center;
        align-items: center;
      }



      .fi-app-bar-menu-items {
        display: none;
      }

      // a:not(:first-child) {
      //   display: none;
      // }
    }

    .fi-top-nav.fi-top-nav-responsive {
      align-self: flex-start;
      border: solid 1px gray;
      transition: color 10s ease;
    }

    .fi-top-nav.fi-top-nav-responsive .fi-app-bar-menu-icon {
      // position: absolute;
      // right: 12px;
      // top: 8px;
      align-self: flex-end;
    }

    // .fi-top-nav.fi-top-nav-responsive #fi-app-bar-menu-icon-id::after {
    //   content: 'close';
    // }

    .fi-top-nav.fi-top-nav-responsive .fi-app-bar-menu-items {
      display: flex;
      flex-direction: column;
      background: linear-gradient(45deg, rgb(246, 246, 248) 0%, rgb(255, 255, 255) 55%, rgb(248, 250, 250) 100%);
      color: #000000;
    }

    .fi-top-nav.fi-top-nav-responsive a {
      //float: none;
      // display: flex;
      // right: 4px;
      // top: 100px;
      //text-align: left;
      // max-width: 100%;
    }

  }


}

.fi-button,
.mdc-button {
  //background: linear-gradient(45deg, rgb(246, 246, 248) 50%, rgb(255, 255, 255) 50%, rgb(248, 250, 250) 50%);

  //color: #000000;
  .mdc-button__label {
    letter-spacing: 3px;
    color: #000000;
  }

  margin:1px;
}



.fi-button {
  background: linear-gradient(45deg, rgb(111, 111, 241) 50%, rgb(169, 235, 235) 50%, rgb(230, 113, 214) 50%);

  //color: #000000;
  .mdc-button__label {

    color: #f1eded;
  }

  margin: 4px;
}


.mdc-layout-grid,
.mdc-layout-grid__inner,
.mdc-layout-grid__cell {
  //border: none; // 1px solid black;
  //border: 1px solid red;
  text-align: center;
  justify-content: center;
  grid-gap: 0px; // to avoid grid-cell overlapping
  max-width: 100%;
  //max-height: 100%;
  overflow: auto;

}

.mdc-layout-grid__inner {
  //width: 99%;
  // max-width: 100%;
}

.mdc-layout-grid__cell {
  justify-content: center;

  img {
    max-width: 100%; // to avoid image overflowing from cell
  }
}

.homepage-banner-big {
  width: inherit;
  min-height: 80vh;
  //border: 1px solid black;
  //background-color: lavender;
  //background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  justify-content: center;
}

.homepage-banner-big2 {
  width: inherit;
  min-height: 30vh;
  //border: 1px solid black;
  //background-color: lavender;
  //background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  justify-content: center;
}

.page-section-title {
  text-transform: uppercase;
  //display: block;
  //text-align: left;
  //font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  //text-align: center;
  //justify-content: center;
  //border: 1px solid black;
  margin: 1px;
  padding: 1px;
  letter-spacing: 3px;
}

.hero-mission-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  //justify-content: center;
  align-items: center;
  //border: 1px solid red;
  width: inherit;
  max-width: 100%;
  height: 80vh;
  max-height: 80vh;
  padding: 0px;
  margin: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1)), url('media/fi-hero-bg-img-new.png');
  //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  //background-size: cover;
  //opacity: 0.3;
  //color: white;


  .hero-img-area {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 100%;
    max-height: 100%;
    //border: 1px solid blue;
    margin: auto;

    //background: linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1) ), url('media/fi-hero-bg-img01.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    //background-repeat: no-repeat;
    //background-position: center center;
    //background-size: contain;

  }

  .hero-img {
    max-width: 100%;
    max-height: 100%;
    //border: 1px solid green;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%;
    //border: 1px solid black;
  }

  .hero-mission-title-area {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 100%;
    max-height: 100%;
    //border: 1px solid purple;
    margin: auto;
    text-align: center; // horizontal center
    padding: 10px;
  }

  .hero-mission-title-area {

    // background: inherit;
    // background: rgba(0, 0, 0, 0.5);
    // background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    // opacity: .5;

    // backdrop-filter: blur(1px) saturate(180%);
    // -webkit-backdrop-filter: blur(1px) saturate(180%);
    // background-color: rgba(255, 255, 255, 0.5);
    // border-radius: 12px;
    // border: 1px solid rgba(209, 213, 219, 0.3);

  }

  .hero-mission-title-area:before {
    // background: inherit;
    // box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
    // // filter: blur(10px);
  }

  p {
    span {
      //text-shadow:5px 5px 10px black;
      /*
      text-shadow:  0 0 5px whitesmoke ,0 0 5px whitesmoke,
                    0 0 5px whitesmoke,0 0 5px whitesmoke,
                    0 0 5px whitesmoke,0 0 5px whitesmoke,
                    0 0 5px whitesmoke,0 0 5px whitesmoke;
      */

      color: hsl(0, 0%, 100%);
      color: hsl(226, 74%, 28%);
      text-shadow: 0 0 10px hsla(212, 29%, 61%, 0.5),
        0 0 10px hsla(212, 29%, 61%, 0.5),
        0 0 10px hsla(212, 29%, 61%, 0.5),
        0 0 5px hsla(212, 29%, 61%, 1),
        0 0 5px hsla(212, 29%, 61%, 1),
        0 0 5px hsla(212, 29%, 61%, 1);

      //background: transparent;
      //background: rgba(255,255,255,0.5);
      //background: linear-gradient(180deg, rgb(243, 235, 235), rgb(227, 227, 240), rgb(226, 222, 236));
      letter-spacing: 2px;
    }
  }
}


@media screen and (max-height:475px) {
  .hero-mission-area {
    width: inherit;
    max-width: 100%;
    height: 70vh;
    max-height: 70vh;
  }

  @media screen and (min-width: 500.1px) {
    .hero-img-area {
      width: 47%;
      max-width: 47%;
      height: 97%;
      max-height: 97%;

    }

    .hero-mission-title-area {
      width: 47%;
      max-width: 47%;
      height: 97%;
      max-height: 97%;
    }
  }

  @media screen and (max-width: 500px) {

    // smaller than 500 w x 475 h
    .hero-img-area {
      width: 47%;
      max-width: 47%;
      height: 97%;
      max-height: 97%;

    }

    .hero-mission-title-area {
      width: 47%;
      max-width: 47%;
      height: 97%;
      max-height: 97%;
    }
  }
}

@media screen and (min-height:475.1px) {
  .hero-mission-area {
    width: inherit;
    max-width: 100%;
    height: 70vh;
    max-height: 70vh;
  }

  @media screen and (min-width: 600.1px) {
    .hero-img-area {
      width: 100%;
      height: 77%;
      max-height: 77%;
    }

    .hero-mission-title-area {
      width: 100%;
      height: 17%;
      max-height: 17%;
    }
  }

  @media screen and (max-width: 600px) {
    .hero-img-area {
      width: 100%;
      height: 47%;
      max-height: 47%;
    }

    .hero-mission-title-area {
      width: 100%;
      height: 47%;
      max-height: 47%;
    }
  }

}

/* @media screen and (min-width: 750px) and (min-height: 300px) and (max-height: 750px) {
    .hero-mission-area{
      max-height: 70vh;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    
      .hero-img-area{
        width: 60%;
        max-width: 60%;
        height: 90%;
        max-height: 90%;
        hero-img, img {
          width: 100%;
          max-width: 100%;
          height: 100%;
          max-height: 100%;
        }
      }
      .hero-mission-title {
        width: 40%;
        max-width: 40%;
        height: 90%;
        max-height: 90%;
        font-size: 1em;
        font-size: 2.5vw;
        letter-spacing: 0.1em;
        p {
          padding: 0px;
          margin: 0px;
          text-align: center;
        }
      }
    }
  }
*/




/*#fi-hero-mission-img-id {
    //background: url('media/fi-hero-bg-img01.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    //width: 80%;
    //height: 80%;
    max-width: 70%;
    max-height: 70%;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    //color: white;
  } */


.mission-title {
  //width: 75%;
  //max-width: 75%;
  //font-size: 16px;
  //font-size: 2.5vw;
  padding: 6vw;
  //padding: 6px; //10vw;
  //text-align: center;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(44, 8, 8, 1), rgba(17, 11, 99, 1), rgba(29, 5, 97, 1));
  //background-repeat: repeat;
  background-size: 80%;
  //animation: animate 10s linear;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  //color: #ffffff;
  letter-spacing: 3px;
  font-weight: normal;

  //@keyframes animate {  0% {   background-position: -500%; }   100% {  background-position: 500%;   }    }

}

// end of mision-title 
.fi-logo-icon {
  width: 56px;
}

.fi-logo-icon-footer {
  width: 200px;
}

.fi-logo-onpage-area {
  width: $fi-logo-onpage-width; // 200px;
  max-width: $fi-logo-onpage-width; //200px;
  height: $fi-logo-onpage-height; //200px;
  max-height: $fi-logo-onpage-height; //200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px lightgray;
  background-color: white;
  border-radius: 8px;
  box-shadow: 2px 2px 2px lightslategrey;
  margin: 8px;
}

@media screen and (max-width:550px) {
  .fi-logo-onpage-area {
    width: 120px; // $fi-logo-onpage-width; // 200px;
    max-width: 120px; // $fi-logo-onpage-width; //200px;
    height: 120px; // $fi-logo-onpage-height; //200px;
    max-height: 120px; // $fi-logo-onpage-height; //200px;
  }
}

@media screen and (min-width:550.1px) {
  .fi-logo-onpage-area {
    width: 160px; // $fi-logo-onpage-width; // 200px;
    max-width: 160px; // $fi-logo-onpage-width; //200px;
    height: 160px; // $fi-logo-onpage-height; //200px;
    max-height: 160px; //  $fi-logo-onpage-height; //200px;
  }
}

.fi-logo-icon-partner1 {
  width: 100%;
  max-width: 180px;
  // padding: 8px;
  // margin: 8px;
}

.fi-logo-icon-partner2 {
  width: 100%;
  max-width: 180px;
  // padding: 8px;
  // margin: 8px;
}

.fi-logo-icon-partner3 {
  width: 100%;
  max-width: 180px;
  // padding: 8px;
  // margin: 8px;
}

.fi-logo-icon2 {
  width: 100%;
  max-width: 100px;
  // padding: 8px;
  // margin: 8px;
}

.fi-logo-icon3 {
  width: 100%;
  max-width: 180px;
  // padding: 8px;
  // margin: 8px;
}

.page-section-logo-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  margin: 8px;
}


/*  .my-card-content {   padding: 16px;  }
  .my-card {  height: 350px;   width: 350px;   }
*/
.page-section-footer-area {
  .mdc-button {
    width: 75%;
  }

  .fi-overflow-none {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// end of page-section-footer-area

/*#fi-main-area-id1 {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('media/fi-card-bg-img07.png');
    border: 5px solid red;

    .fi-hero-area {
        //background-image: linear-gradient(180deg, rgb(177, 16, 70), rgb(141, 160, 245), rgb(9, 172, 236));
        display: block;
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        //background-position: center;
        //background-repeat: no-repeat;
        //background-size: cover;
        //position: relative;         
  
        .fi-hero-message {
    
          text-align: center;
          //position: absolute;
          //top: 50%;
          //left: 50%;
          //transform: translate(-50%, -50%);
          color: white;     
        }
    }
  }*/

.main-area {
  //color: #f3f3f3;
  /*display: block;
    margin: auto;
    font-size: 12px;
    padding: 5px;
    width: 100%;
    //margin: 5px;
    //justify-content: center;
    text-align: center;
    */
  font-size: 16px;
  //border: 1px solid rgba(192,192,192,0.5);
  width: inherit;
  max-width: 100%;

  .mdc-layout-grid,
  .mdc-layout-grid__inner {
    padding: 0px;
    margin: 0px;
  }

  .main-cell-view {
    //
  }

  //////////////////////

  .fi-office-cards-collection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    //align-items: center;
    //width: 100%; 
    //height: 100%;
    max-width: 100%;

    padding: 6px;
    border-radius: 6px;
    overflow: hidden;

    .fi-office-card {
      //width: 50px;  
      //height: 50px;
      width: 100%; //380px;  // must be lower than flip-card
      height: $fi-office-card-hieght; //210px;  // must be lower than flip-card
      max-width: 100%;
      //display: flex;
      margin: 6px;
      padding: 1px;
      text-align: left;
    }

    .fi-oca-address {
      ul {
        list-style-type: none;
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 0.5px;
        padding-bottom: 0.5px;
      }

      li {
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 0px;
      }
    }

    .fi-oca-map {
      //position: relative;
      overflow: hidden;

      iframe,
      object,
      embed {
        //position: absolute;
        //width: 100%;
        max-width: 100%;
        //height: 100%;
      }
    }

    .mdc-card {

      display: flex;
      align-items: center;
      justify-content: center;
      width: $fi-office-card-width; //380px;  // must be lower than flip-card
      max-width: 100%;
      height: $fi-office-card-hieght; //210px;  // must be lower than flip-card
      //border: 1px solid rgba(2,0,36,1);
      border-radius: inherit;
      background-color: var(--mdc-theme-background, #fff);
      color: #000000;

      //transition: border-radius 100ms cubic-bezier(.4, 0, .2, 1);
      //z-index: 3;
      .mdc-card__primary-action {
        display: flex;
        width: 100%;
        max-width: 100%;
        //height: 90%;
        //border: 1px solid rgba(2,0,36,1);
        //  display: flex;
        align-items: center;
        justify-content: center;
        //background-color: rgb(123, 191, 231);
        //opacity: 0.3;
        //background: rgba(0, 128, 0, 0.2);

        //background: linear-gradient(45deg, rgb(242, 242, 248) 0%, rgb(165, 165, 240) 35%, rgb(210, 238, 157) 100%);

      }

      .mdc-card__media {
        display: flex;
        align-items: center;
        justify-content: center;

        //background-image: linear-gradient(45deg, rgba(255,204,204,1)), url("pretty.jpg");

      }

      .mdc-card__media-content {
        position: relative;
      }

      ul {
        text-align: left;
      }

    }

    // end of mdc-card
  }

  // end of fi-officelocations-cards-collection

  //////////////////////
  .fi-flip-card {
    display: block;
    width: $fi-flip-card-width; //480px;  // should be same ore more than mdc-card it applies to
    height: $fi-flip-card-hieght; //410px; // should be same ore more than mdc-card it applies to
    max-width: 100%;

    margin: 2px;
    perspective: 500px;
  }


  .fi-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100%;
    //cursor: pointer;

    transform-style: preserve-3d;
    transform-origin: center right;
    transition: transform 0.5s;
  }

  /*.fi-flip-card:hover .fi-flip-card-inner {
      transform: translateX(-100%) rotateY(-180deg);
    }*/

  .fi-flip-card-inner.is-flipped {
    transform: translateX(-100%) rotateY(-180deg);
  }

  .fi-flip-card-front,
  .fi-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    line-height: 260px;
    border-radius: 10px;
    //color: white;
    //text-align: center;
    //font-weight: bold;
    //font-size: 40px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .fi-flip-card-front {
    //background: red;
  }

  .fi-flip-card-back {
    //background: blue;
    //text-align: ;
    transform: rotateY(180deg);
  }

  /*.fi-product-card-back {        
      .mdc-card__media {
        .mdc-card__media-content {              
            .fi-product-card-back-title, span {
              font-size: 12px;
            }
            .fi-product-card-back-content, ul, li {
              font-size: 12px;
            }  
        }
      }
    }*/


  //////////////////////
  .fi-product-cards-collection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    //align-items: center;
    //width: 100%; 
    //height: 100%;
    max-width: 100%;

    padding: 6px;
    border-radius: 6px;
    overflow: hidden;

    .fi-product-card {
      //width: 50px;  
      //height: 50px;
      //display: block;
    }

    .fi-card-title {
      text-align: center;
      justify-content: center;
    }

    .mdc-card {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $fi-card-width; //380px;  // must be lower than flip-card
      height: $fi-card-hieght; //210px;  // must be lower than flip-card
      max-width: 100%;
      //border: 1px solid rgba(2,0,36,1);
      border-radius: inherit;
      background-color: var(--mdc-theme-background, #fff);
      color: #000000;

      //transition: border-radius 100ms cubic-bezier(.4, 0, .2, 1);
      //z-index: 3;
      .mdc-card__primary-action {
        display: flex;
        width: 90%;
        height: 90%;
        max-width: 90%;
        // border: 1px solid rgba(2,0,36,1);
        //  display: flex;
        align-items: center;
        justify-content: center;
        //background-color: rgb(123, 191, 231);
        //opacity: 0.3;
        //background: rgba(0, 128, 0, 0.2);

        //background: linear-gradient(45deg, rgb(242, 242, 248) 0%, rgb(165, 165, 240) 35%, rgb(210, 238, 157) 100%);

      }

      .mdc-card__media {
        display: flex;
        align-items: center;
        justify-content: center;

        //background-image: linear-gradient(45deg, rgba(255,204,204,1)), url("pretty.jpg");

      }

      .mdc-card__media-content {
        position: relative;
      }

      ul {
        text-align: left;
      }

      //        .fi-product-card-back {
      /*display: block;
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          border: 4px solid red;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          */
      /*.fi-product-card-back-title{
            width: 100%;
            height: 20%;
            border: 2px solid green;
            text-align: center;
          }
          .fi-product-card-back-content{
            width: 100%;
            height: 80%;
            border: 2px solid blue;
            text-align: left;
          }*/
      /*fi-product-card-back">
          <div class="mdc-card__media mdc-card__media--square">
            <div class="mdc-card__media-content mdc-typography--headline5">
              <span>Architecture & Design Services</span>
              <ul class="mdc-typography--subtitle1">
                Architecture & design with your vision & strategy
                <li class="mdc-typography--subtitle2">Business Architecture</li>*/



    }

    // end of mdc-card
  }

  // end of fi-product-cards-collection

  /*
      @media screen and (min-height: 600.1px) {
        //@media screen and (min-width: 500.1px){
          $fi-flip-card-width: 470px;
          $fi-flip-card-hieght: 410px;
          $fi-office-card-width: 470px;
          $fi-office-card-hieght: 410px;
          $fi-card-width: 470px;
          $fi-card-hieght: 410px;    
          .fi-flip-card {
            width: $fi-flip-card-width ;//480px;  // should be same ore more than mdc-card it applies to
            height: $fi-flip-card-hieght ;//410px; // should be same ore more than mdc-card it applies to
          }
          .fi-product-cards-collection {
            .mdc-card {
              width: $fi-card-width ;//380px;  // must be lower than flip-card
              height: $fi-card-hieght ;//210px;  // must be lower than flip-card
            }
          }

              //}
      //  @media screen and (max-width: 500px){
      // }
      }

      @media screen and (max-height: 600px) {
        @media screen and (min-width: 420.1px){          
          $viewable-height: 70vh;
          $fi-flip-card-width: $viewable-height;
          $fi-flip-card-hieght: $viewable-height;
          $fi-office-card-width: 400px;
          $fi-office-card-hieght: 500px;
          $fi-card-width: $viewable-height;
          $fi-card-hieght: $viewable-height;
          .fi-flip-card {
            width: $fi-flip-card-width ;//480px;  // should be same ore more than mdc-card it applies to
            height: $fi-flip-card-hieght ;//410px; // should be same ore more than mdc-card it applies to
          }
          .fi-product-cards-collection {
            .mdc-card {
              width: $fi-card-width ;//380px;  // must be lower than flip-card
              height: $fi-card-hieght ;//210px;  // must be lower than flip-card
            }
          }
        }
        @media screen and (max-width: 420px){
          $viewable-height: 70vw;
          $fi-flip-card-width: $viewable-height;
          $fi-flip-card-hieght: $viewable-height;
          $fi-office-card-width: 400px;
          $fi-office-card-hieght: 500px;
          $fi-card-width: $viewable-height;
          $fi-card-hieght: $viewable-height;
          .fi-flip-card {
            width: $fi-flip-card-width ;//480px;  // should be same ore more than mdc-card it applies to
            height: $fi-flip-card-hieght ;//410px; // should be same ore more than mdc-card it applies to
          }
          .fi-product-cards-collection {
            .mdc-card {
              width: $fi-card-width ;//380px;  // must be lower than flip-card
              height: $fi-card-hieght ;//210px;  // must be lower than flip-card
            }
          }
        }
        
      } 
      */


  #fi-oc-01-id {
    background: white; //linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0) ), url('media/fi-card-bg-img01.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    // color: white;
  }


  #fi-pca-01-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img01-b.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-02-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img02-b.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-03-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img03-b.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-04-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img04-b.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-05-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img05-b.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-06-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img06-b.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-07-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img07-b.png');
    //background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) ), url('media/fi-card-bg-img07.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    //background-size: 100% 100%;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-08-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img08-b.png');
    //background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) ), url('media/fi-card-bg-img08.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-09-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img09-b.png');
    //background: linear-gradient( rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) ), url('media/fi-card-bg-img09.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    //background-size: cover;
    background-position: center center;
    background-size: contain;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-10-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img10-b.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    //background-size: cover;
    background-position: center center;
    background-size: contain;
    //opacity: 0.3;
    color: white;
  }

  #fi-pca-11-id {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0)), url('media/fi-card-bg-img11-b.png');
    //background-image: url('media/fi-card-bg-img01.png');  //note: needs style loader in webpack
    background-repeat: no-repeat;
    //background-size: cover;
    background-position: center center;
    background-size: contain;
    //opacity: 0.3;
    color: white;
  }

  .fi-card-services {
    //display: flex;
    width: 100%;
    height: 100%;
    max-width: 100%;
    justify-content: space-evenly;

    .fi-card-content {
      padding: 16px;
    }

    //end of fi-card-content

    .fi-card-tray {
      //display : inline-box;
      //justify-content: center;
    }

    // end of fi-card-tray

    .fi-card {
      //border: 1px solid blue;
      padding: 5px;
      margin: auto;
      //height: 600px;
      width: 900px;
      max-height: 100%;
      max-width: 100%;

      .fi-card-image {
        width: 100%;
        //height: 25vh;
      }

      .fi-card-sp {
        background-image: linear-gradient(rgba(255, 120, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 240, .33));
      }





    }

    // end of fi-car

    .material-image .material-image__background {
      fill: #9a0007;
    }

    .material-image .material-image--shape-circle,
    .material-image .material-image--shape-triangle {
      fill: #ffffff;
    }

    .material-image .material-image--shape-intersection {
      fill: #9a0007;
    }



  }

  // end of fi-card-services


  $fi-form-width: 750px;
  $fi-form-height: 750px;
  $fi-form-width-compact: 750px;
  $fi-form-height-compact: 350px;

  $fi-form-height-min: 750px;
  $fi-form-item-width: 325px;
  $fi-form-height-min-compact: 350px;

  //$fi-form-item-height: ;
  $fi-form-item-textarea-width: 325px;
  $fi-form-item-textarea-width-long: 700px;
  $fi-form-item-textarea-height: 100px;
  $fi-form-item-textarea-height-high: 150px;
  //$fi-form-item-textarea-height: ;
  $fi-form-item-switch-width: 325px;

  .fi-contact-us-form {
    width: $fi-form-width; //750px;
    min-height: $fi-form-height-min; // 650px;
  }

  .fi-contact-us-form-compact {
    width: $fi-form-width-compact; //750px;
    min-height: $fi-form-height-min-compact; // 650px;
  }

  .fi-contact-us-form,
  .fi-contact-us-form-compact {
    display: block;
    margin: auto;
    background-color: #ffffff; //inherit;
    //width: $fi-form-width;//750px;
    //height: $fi-form-height;
    max-width: 100%;
    //min-height: $fi-form-height-min; // 650px;
    //max-height: 100vh;
    //max-width: 95%;
    //justify-content: center; 
    overflow: auto; // required to keep content in within parent container
    //grid-gap: 0px;
    //border: 1px solid black;
    //font-size: 12px ;
    //margin: 6px;
    //padding: 06px;
    // border: 1px solid blue;

    .fi-form-heading {
      display: block;
      //text-align: left;
      //font-size: 16px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      justify-content: center;
      //border: 1px solid black;
      margin: 1px;
      padding: 1px;
      letter-spacing: 3px;
    }

    ;

    .fi-form-subheading {
      /*
        display: inline-block;
        //text-align: left;
        //text-transform: lowercase;
        //font-weight: bold;
        text-align: center;
        justify-content: center;
        //border: 1px solid black;
        margin: 0.1px;
        padding: 0.1px;
          p {
            margin: 0.1px;
            padding: 0.1px;
          }
          */
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: auto;
      text-align: center; // horizontal center        
      padding: 5px;

      p,
      span {
        padding: 0px;
        margin: 0px;
      }
    }

    ;


    .fi-form-dtls {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-around;
      font-weight: bold;
      margin: 0px;
      padding: 5px;

      .fi-form-item {
        display: block;
        width: $fi-form-item-width; //325px;
        max-width: 100%;
        overflow: auto;
        margin: 1 auto;
        padding: 2px;

        .mdc-text-field {
          //@include textfield.density(-3);
          height: 44px;
          //font-size: 10px ;
          width: $fi-form-item-width; //325px;
          max-width: 100%;
          text-align: left;
        }

        ;
      }

      // end of fi-form-item

      .fi-form-item-spl {
        display: block;
        width: $fi-form-item-textarea-width; //325px;
        max-width: 100%;
        overflow: auto;
        margin: 1 auto;
        padding: 2px;

        .mdc-text-field,
        .mdc-text-field--textarea {
          // @include textfield.density(-4);
          //@include textfield.density(-3);
          height: $fi-form-item-textarea-height-high; // 100px;
          width: $fi-form-item-textarea-width; //325px;
          max-width: 100%;
          text-align: left;
        }

        ;

        .mdc-text-field__input {
          margin: 0px;
        }

        .mdc-text-field__icon {
          align-self: self-start;
        }

        ;

      }

      // end of fi-form-item-spl
      //check-box related
      .fi-form-item-checkbox-area {
        display: block;
        max-width: 100%;
        //width: $fi-form-item-textarea-width ;
        width: $fi-form-item-switch-width;
        max-width: 100%;
        //margin: 0px;
        //padding: 1px;
        //border: 1px solid red;

        .fi-form-item-switch {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          margin: 5px;
          padding: 5px;
          //border: 1px solid purple;
          background: linear-gradient(45deg, rgb(246, 246, 248) 50%, rgb(255, 255, 255) 50%, rgb(248, 250, 250) 50%);

          .fi-switch-title {
            margin: 0px;
            padding: 5px;

            lable {
              margin: 0px;
              padding: 5px;
            }

            //border: 1px solid green;
          }

          .fi-switch-body {
            padding: 5px;
            //border: 1px solid blue;
          }
        }

        .fi-form-item-checkbox {
          display: flex;
          width: 100%; //$fi-form-item-textarea-width; //325px;
          max-width: 100%;
          overflow: none;
          //margin: auto;
          margin: 0.1px;
          padding: 0.1px;
          text-align: center;
          justify-content: center;

          //padding: 6px;
          #fi-cf-acpt-cb-id:required:invalid {
            background: #ff0000;
          }

          .mdc-form-field {
            display: flex;
            //width: 90%;
            max-width: 100%;
            align-items: center;
            margin: 0px;
            padding: 0px;
            //@include checkbox.density(-3);
          }

          mdc-checkbox {
            //@include checkbox.density(-3);
            //@include checkbox.ripple-size(2);
            margin: 0px;
            padding: 0px;
          }

          #fi-cf-acpt-lb-id,
          #fi-cf-loc-acpt-lb-id {
            font-weight: bold;
            //width: 150px;
            //max-width: 95%;
            text-align: right;
            margin: 0px;
            padding: 0px;
            word-wrap: break-word;
          }

          #fi-cf-loc-acpt-cb-lable-id,
          #fi-cf-acpt-cb-lable-id {
            //width: 410px;
            //min-width:100px;
            //max-width: 100%;
            text-align: left;
            overflow: none;
            margin: 0px;
            padding: 0px;
            word-wrap: break-word;
          }
        }

        ; // end of .fi-form-item-checkbox
      }

      // end of fi-form-item-checkbox-area
      /* @media screen and (max-width: 649px ) {
          .fi-form-item-spl {
            display: block;
            min-width:350px;
            max-width:100%;
            overflow: auto;
            
            .mdc-text-field {
              // height: 40px;
               font-size: 12px ;
               min-width:300px;
               max-width:100%;
               text-align: center;
            };
          } // end of .fi-form-item
        } */

      @media screen and (min-width: 675px) and (max-width:701px) {
        .fi-form-item-spl {
          display: block;
          //min-width:100%;

          max-width: 100%;
          //overflow: auto;  

          .mdc-text-field,
          .mdc-text-field--textarea {
            // height: 56px;
            //min-width:100%;
            max-width: 100%;
          }

          ;

          .mdc-text-field__icon {
            align-self: self-start;
          }

          ;
        }

        ; // end of .fi-form-item-spl

        .fi-form-item-checkbox-area {
          //width: $fi-form-item-textarea-width;
          width: $fi-form-item-switch-width;

          max-width: 100%;
        }

      }

      @media screen and (min-width: 702px) {
        .fi-form-item-spl {
          display: block;
          width: $fi-form-item-textarea-width-long; //700px;
          max-width: 100%;
          //overflow: auto;  

          .mdc-text-field,
          .mdc-text-field--textarea {
            // height: 56px;
            width: $fi-form-item-textarea-width-long; //700px;
            max-width: 100%;
            height: $fi-form-item-textarea-height;
          }

          ;

          .mdc-text-field__icon {
            align-self: self-start;
          }

          ;
        }

        ; // end of .fi-form-item-spl

        .fi-form-item-checkbox-area {
          width: $fi-form-item-textarea-width-long;
          max-width: 100%;
        }
      }

    }

    // end of .fi-form-dtls

    .fi-form-button-container {
      display: block;
      justify-content: space-around;
      flex-wrap: wrap;

      .fi-form-sbt-button {
        //width: 33%;
        //max-width: 33%;
      }

      .fi-form-btn-status {
        visibility: visible;
        border: 1px solid rgb(250, 241, 241);
        width: 100%;
        max-width: 99%;
        //background-color: rgb(250, 241, 241);
        background: linear-gradient(45deg, rgb(246, 246, 248) 50%, rgb(255, 255, 255) 50%, rgb(248, 250, 250) 50%);
        font-size: 12px;
        color: blue;
      }

      .fi-form-btn-status-long {
        visibility: visible;
        border: 1px solid rgb(250, 241, 241);
        width: 100%;
        max-width: 99%;
        background-color: rgb(250, 241, 241);
        background: linear-gradient(45deg, rgb(246, 246, 248) 50%, rgb(255, 255, 255) 50%, rgb(248, 250, 250) 50%);
        font-size: 12px;
        color: blue;
      }
    }


  }

  // end of .fi-contact-us-form

}

// end of .main-area

//icon-bar related
.fi-icon-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fi-icon {
  height: inherit;
  width: inherit;
  padding: 10px;

  a {
    color: #ff0000;

    img {
      width: 42px;
      height: 42px;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }
}

//end of icon-bar 
.animate-element {
  height: inherit; //250px;
  width: inherit; //  250px;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: red;
  animation-name: stretch;
  animation-duration: 50s;
  animation-timing-function: ease-out;
  animation-delay: 10;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
    transform: scale(.5);
    background-color: red;
    //border-radius: 100%;
  }

  50% {
    background-color: orange;
  }

  100% {
    transform: scale(1);
    background-color: yellow;
  }
}



.fi-footer-area {
  //display: flex;
  background: #000000; //linear-gradient(0deg, rgba(2, 0, 36, 0.01) 0%, rgba(9,9,121,0.5) 35%, rgba(0,212,255,0.1) 100%);
  //background: #d3d3d3 ;// rgba(2,0,36,1); // linear-gradient(45deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  //background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgb(141, 141, 156) 35%, rgb(248, 250, 250) 100%);
  color: #fafafa;
  font-size: 16px;
  padding: 5px;
  margin: 5px;
  justify-content: center;
  // border: 4px solid red;

  .fi-footer-area-nav {


    .fi-footer-area-logo,
    .fi-footer-area-nav-sec1,
    .fi-footer-area-nav-sec2,
    .fi-footer-area-nav-sec3 {
      // display: 
      // min-width: 380px;
    }

    .fi-footer-section-title {
      //text-align: left;
      text-transform: uppercase;
      font-weight: bold;
      text-align: left;
      justify-content: left;
      //border: 1px solid black;
    }

    ;

    .mdc-list {
      list-style: none;
      //padding-left: 0;
      //padding-bottom: 0;
      text-align: center;
      justify-content: center;
    }

    ;

    .mdc-list-item {
      display: block;
      margin: 0.1px;
      //padding: 0.5px;
      max-height: 20px;
      text-align: left;
      justify-content: center;
    }

    ;

    a {
      color: #fafafa; // #6a737c;
      //font-size: 14px;
      text-decoration: none;
      //padding: 0.5px;
    }

    ;

  }

  .fi-footer-area-copyright {
    .mdc-list {
      list-style: none;
      padding-left: 0;
    }

    .mdc-list-item {
      display: inline;
      margin: 0.8px;
    }

    a {
      color: #fafafa; // #6a737c;
      //font-size: 14px;
      text-decoration: none;
    }
  }
}


/*.mdc-list, .navList {
  list-style: none;
  height: 32px;
  padding-left: 0;
}

// Style the list items side by side
.mdc-list-item, .navListItem {
  display: inline;
  margin: 0 8px;
}*/